import styled from 'styled-components'

export const StyledLoginWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.bp.md } {
    flex-direction: row;
    padding: 0;
    height: 100vh;
  }
`

export const StyledLoginForm = styled.div`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.bp.md} {
    width: 41%;
  }
`

export const StyledLoginBackground = styled.div`
  display: none;
  background-color: #5C53D1;

  img {
    width: 85%;
    transform: translateX(20%);
  }

  @media ${({ theme }) => theme.bp.md } {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    overflow: hidden;
  }

  @media ${({ theme }) => theme.bp.md } {
    width: 59%;
  }
`