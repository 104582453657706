import React from "react"
import SEO from './../global/seo'
import { Head } from './../global/head'
import { LoginPage } from './../layouts/LoginPage'

const IndexPage = () => (
  <>
    <Head />
    <SEO title="Home" />
    <LoginPage />
  </>
)

export default IndexPage
