import React from 'react'

import { StyledLabel } from './styled'

export const Label = ({ labelFor, text }) => {
  return (
    <StyledLabel htmlFor={labelFor}>
      { text }
    </StyledLabel>
  )
}
