import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { totalCenter } from './../../global/mixins'
import { Heading1, ButtonTypo, LabelTypo, Paragraph1 } from './../../global/typography'

export const StyledLoginForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 20px 20px 20px;

  @media ${({ theme }) => theme.bp.md } {
    padding: 50px;
    height: 100%;
  }

  @media ${({ theme }) => theme.bp.lg } {
    padding: 50px 130px;
  }
`

export const StyledLoginTitle = styled.h1`
  ${Heading1};
  color: ${({ theme }) => theme.colors.darkprimary };
  padding-bottom: 30px;
  margin: 0;
`

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  input[type='submit'] {
    ${ButtonTypo};
    color: ${({ theme }) => theme.colors.white };
    background-color: ${({ theme }) => theme.colors.mutedprimary };
    border-radius: ${({ theme }) => theme.radius.medium };
    width: 100%;
    text-align: center;
    padding: 12px;
    cursor: pointer;
    transition: ${({ theme }) => theme.transitions.fast };
    margin: 0;

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary };
    }
  }

  fieldset {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`

export const StyledRecovery = styled(Link)`
  ${LabelTypo};
  color: ${({ theme }) => theme.colors.mutedprimary };
  margin-top: 10px;
  margin-bottom: 40px;
  text-align: right;
  text-decoration: none;
`

export const StyledDescription = styled.p`
  ${Paragraph1};
  color: ${({ theme }) => theme.colors.darkgray };
  padding-top: 25px;

  a {
    color: ${({ theme }) => theme.colors.primary };
    font-weight: 700;
    text-decoration: none;
  }
`

export const StyledToggle = styled.div`
  ${totalCenter};
  position: absolute;
  right: 0;
  top: 43px;
  cursor: pointer;
  height: 42px;
  width: 62px;

  ${({ isVisible }) => isVisible && css`
    &:before {
      position: absolute;
      top: 11px;
      right: 30px;
      width: 2px;
      height: 20px;
      content: '';
      background-color: ${({ theme }) => theme.colors.gray };
      transform: rotate(45deg);
    }
  `}
`