import React, { useState, createRef } from 'react'
import { Link, navigate } from 'gatsby'
import togglePassword from './../../images/icons/toggle-password.svg'
import { SlideInAnimation } from './../SlideInAnimation';
import { Label } from './../Label'
import { Input } from './../Input'

import {
  StyledLoginForm,
  StyledLoginTitle,
  StyledForm,
  StyledRecovery,
  StyledDescription,
  StyledToggle
} from './styled'

export const LoginForm = () => {
  const [idNumber, setIdNumber] = useState('');
  const [password, setPassword] = useState('');
  const [idError, setIdError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [visiblePassword, setVisiblePassword] = useState(false)
  const passwordRef = createRef()

  const handleSubmit = e => {
    e.preventDefault();

    idNumber.length ? setIdError(false) : setIdError(true)
    password.length ? setPasswordError(false) : setPasswordError(true)

    if (idNumber.length && password.length) {
      navigateToDashboard()
    }
  }

  const navigateToDashboard = () => {
    navigate('/dashboard', { replace: true })
  }

  const handleClick = () => {
    setIdError(false);
    setPasswordError(false);
    setIdNumber('876 546 532')
    setPassword('876 546 532')
  }
  
  const handleTogglePassword = () => {
    const { current: { type } } = passwordRef
    passwordRef.current.type = type === 'password' ? 'text' : 'password'
    setVisiblePassword(!visiblePassword)
  }

  return (
    <StyledLoginForm>
      <SlideInAnimation>
        <StyledLoginTitle>
          Log In
        </StyledLoginTitle>
        <StyledForm>
          <fieldset>
            <Label labelFor={'ID Number'} text={'Id number'} />
            <Input 
              type='text' 
              name='ID Number' 
              onClick={handleClick} 
              onChange={(e) => setIdNumber(e.currentTarget.value)} 
              value={idNumber} 
              error={idError}
            />
          </fieldset>
          <fieldset>
            <Label labelFor={'Password'} text={'Password'} />
            <Input 
              type='password' 
              name='Password' 
              onClick={handleClick} 
              onChange={(e) => setPassword(e.currentTarget.value)} 
              value={password}
              error={passwordError}
              reference={passwordRef}
            />
            <StyledToggle onClick={handleTogglePassword} isVisible={visiblePassword}>
              <img src={togglePassword} alt="Show password" />
            </StyledToggle>
          </fieldset>
          <StyledRecovery to='/'>
            Recovery password
          </StyledRecovery>
          <input 
            type='submit' 
            text='Log in'
            onClick={handleSubmit}
          />
          <StyledDescription>
            Don't have an account? <Link to="/dashboard">Create an account</Link>
          </StyledDescription>
        </StyledForm>
      </SlideInAnimation> 
    </StyledLoginForm> 
  )
}
