import styled from 'styled-components'
import { LabelTypo } from './../../global/typography'

export const StyledLabel = styled.label`
  ${LabelTypo};
  color: #94A3B8;
  margin-top: 20px;
  margin-bottom: 5px;
  display: inline-block;
  width: 100%;
`