import React from 'react'
import { ThemeProvider } from 'styled-components'
import { theme } from '../../global/variables'
import { SiteLogo } from '../../components/SiteLogo'
import { LoginForm } from '../../components/LoginForm'
import LoginBackground from '../../images/login-background.png'

import { 
  StyledLoginWrapper,
  StyledLoginForm,
  StyledLoginBackground
} from './styled'

export const LoginPage = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <StyledLoginWrapper>
          <StyledLoginForm>
            <SiteLogo 
              path={'/'}
            />
            <LoginForm />
          </StyledLoginForm>
          <StyledLoginBackground>
            <img src={LoginBackground} alt="" />
          </StyledLoginBackground>
        </StyledLoginWrapper>
      </ThemeProvider>
    </>
  )
}
